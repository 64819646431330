





import { Component, Vue, Prop } from 'vue-property-decorator';
import NumberCard from '../../dealer/cards/NumberCard.vue';
import { DashboardStatistics_auditStatistics } from '@/types/intrador';

@Component({
  components: {
    NumberCard,
  },
})
export default class Inspections extends Vue {
  @Prop(Object) private auditStatistics!: DashboardStatistics_auditStatistics;
  @Prop(String) private currentDateId!: string;

  private get inspections() {
    return this.auditStatistics.inspectionsByMonth.find((status) =>
      status?.name === this.currentDateId)?.count ?? 0;
  }
}
