































































import {Component, Mixins, Prop} from 'vue-property-decorator';
import MonthlyStatistics from './cards/tables/MonthlyStatistics.vue';
import Inspections from './cards/Inspections.vue';
import InProgressAudits from './cards/InProgressAudits.vue';
import AuditsWithAnomalies from './cards/AuditsWithAnomalies.vue';
import MonthlyAuditedLocations from './cards/charts/MonthlyAuditedLocations.vue';
import MonthlyInspectionsSituations from './cards/charts/MonthlyInspectionsSituations.vue';
import MonthlyRatedAudits from './cards/charts/MonthlyRatedAudits.vue';
import PerformedAudits from './cards/PerformedAudits.vue';
import ToPlanAudits from './cards/ToPlanAudits.vue';
import {longMonthTranslation, shortMonthTranslation} from '@/filters/vue/dateTime';
import AccountManagerFilterMixin from '../../filters/dataTable/AccountManagerFilterMixin';
import {DashboardStatistics} from '@/types/intrador';

export interface ChartLabel {
  names: string[];
  numbers: Date[];
}

@Component({
  components: {
    MonthlyStatistics,
    Inspections,
    InProgressAudits,
    AuditsWithAnomalies,
    MonthlyAuditedLocations,
    MonthlyInspectionsSituations,
    MonthlyRatedAudits,
    PerformedAudits,
    ToPlanAudits,
  },
})
export default class Statistics extends Mixins(AccountManagerFilterMixin) {
  @Prop(Object) private data!: DashboardStatistics;
  @Prop(Boolean) private isLoading!: boolean;
  @Prop(Boolean) private loading!: boolean;
  @Prop(String) private title!: string;

  private currentDate!: Date;
  private labels: ChartLabel = { names: [], numbers: [] };

  private longMonthTranslation = longMonthTranslation;

  private created() {
    this.currentDate = new Date();
    const selectedDate = new Date();

    for (let i = 0; i < 12; i++) {
      this.labels.names.push(shortMonthTranslation(selectedDate.getMonth() + 1) + ' ' + selectedDate.getFullYear());
      this.labels.numbers.push(new Date(selectedDate));

      selectedDate.setMonth(selectedDate.getMonth() - 1);
    }
  }

  private get currentDateVariable() {
    const currentMonth = this.currentDate.getMonth() + 1;
    const currentYear = this.currentDate.getFullYear();
    return currentYear + '-' + currentMonth;
  }
}
