



















import { Component, Prop, Vue } from 'vue-property-decorator';
import { DashboardStatistics_auditStatistics } from '@/types/intrador';
import { VueFrappe } from 'vue2-frappe';
import { ChartLabel } from '@/pages/Dashboard.vue';
import Card from '@/layouts/back-office/elements/Card.vue';
import Spinner from '@/layouts/back-office/elements/Spinner.vue';

@Component({
  components: {
    Card,
    Spinner,
    VueFrappe,
  },
})

export default class MonthlyAuditedLocations extends Vue {
  @Prop(Object) protected auditStatistics!: DashboardStatistics_auditStatistics;
  @Prop(Object) protected labels!: ChartLabel;
  @Prop(Number) protected totalLocations!: number;
  @Prop(Boolean) protected loading!: boolean;

  private get chartDataProgress() {
    const locationsArray: number[] = [];
    if (this.auditStatistics) {
      this.labels.numbers.forEach((date: any) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString();
        const name = year + '-' + month;

        const locationsPerMonth = this.auditStatistics.addressesByMonth.find((location) => location?.name === name);

        if (locationsPerMonth && this.totalLocations) {
          if (locationsPerMonth.count < this.totalLocations) {
            locationsArray.push(Number(((100 * locationsPerMonth.count) / this.totalLocations).toFixed(2)));
          } else {
            locationsArray.push(100);
          }
        } else {
          locationsArray.push(0);
        }
      });

      // We do this because Frappe is not redrawing automatically
      // this.$nextTick(() => {
      //   this.$forceUpdate();
      // });

      return [
        {
          values: locationsArray,
          chartType: 'bar',
        },
      ];
    }
  }
}
