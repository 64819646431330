





















import {Component, Mixins, Watch} from 'vue-property-decorator';
import AccountManagerFilterMixin from '@/filters/dataTable/AccountManagerFilterMixin';
import {Meta} from '@sophosoft/vue-meta-decorator';
import Statistics from '@/components/dashboard/Statistics.vue';

export interface ChartLabel {
  names: string[];
  numbers: Date[];
}

@Component({
  components: {
    Statistics,
  },
})
export default class Dashboard extends Mixins(AccountManagerFilterMixin) {
  private variables: any = {};

  private get dashboardVariables() {
    const currentYear = (new Date()).getFullYear();
    const currentMonth = (new Date()).getMonth() + 1;
    const lastDayOfMonth = (new Date(currentYear, currentMonth, 0));
    const lastDayOfMonthDate = lastDayOfMonth.getDate();

    const lastYear = (new Date(currentYear - 1, currentMonth, 1));
    const lastYearYear = lastYear.getFullYear();
    const lastYearMonth = lastYear.getMonth() + 1;

    const variables: any = {
      dateMonth: {
        start: `${currentYear}-${currentMonth}-01T00:00:00Z`,
        end: `${currentYear}-${currentMonth}-${lastDayOfMonthDate}T23:59:59Z`,
      },

      dateYear: {
        start: `${lastYearYear}-${lastYearMonth}-01T00:00:00Z`,
        end: `${currentYear}-${currentMonth}-${lastDayOfMonthDate}T23:59:59Z`,
      },
    };

    if ('filter-accountManagerIds' in this.$route.query) {
      if (typeof this.$route.query['filter-accountManagerIds'] === 'string' && this.$route.query['filter-accountManagerIds'].length > 0) {
        variables.accountManagerIds = this.$route.query['filter-accountManagerIds'].split(',');
      }
    } else if (this.defaultAccountManager) {
      variables.accountManagerIds = [this.defaultAccountManager.id];
    }

    return variables;
  }

  @Watch('$route.query')
  @Watch('dashboardVariables', {immediate: true})
  private onVariablesChanged() {
    this.variables = {
      ...this.dashboardVariables,
    };
  }

  @Meta
  private getMetaInfo() {
    return {
      title: this.$it('menu.management.dashboard.title', 'Management Dashboard') as string,
    };
  }
}
