


















import { Component, Prop, Vue } from 'vue-property-decorator';
import { DashboardStatistics_inspectionStatistics, EquipmentSituation } from '@/types/intrador';
import { VueFrappe } from 'vue2-frappe';
import { ChartLabel } from '@/pages/Dashboard.vue';
import { getEquipmentSituationColor, getEquipmentSituationTranslation } from '@/plugins/snapshot/SnapshotSituation';
import Card from '@/layouts/back-office/elements/Card.vue';
import Spinner from '@/layouts/back-office/elements/Spinner.vue';

@Component({
  components: {
    Card,
    Spinner,
    VueFrappe,
  },
})

export default class MonthlyInspectionsSituations extends Vue {
  @Prop(Object) protected inspectionStatistics!: DashboardStatistics_inspectionStatistics;
  @Prop(Object) protected labels!: ChartLabel;
  @Prop(Boolean) protected loading!: boolean;

  private get colors() {
    return [
      getEquipmentSituationColor(EquipmentSituation.FOUND),
      getEquipmentSituationColor(EquipmentSituation.SOLD),
      getEquipmentSituationColor(EquipmentSituation.IN_DEMONSTRATION),
      getEquipmentSituationColor(EquipmentSituation.RENTAL),
      getEquipmentSituationColor(EquipmentSituation.OTHER_SITUATION),
    ];
  }

  private get info() {
    return this.$it('monthly-inspection-situations.info', 'This graph shows the amount of inspections by equipment situation, inspections without a situation are not included.');
  }

  private get foundByMonth() {
    const inDemoArray: number[] = [];

    this.labels.numbers.forEach((date: any) => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString();
      const name = year + '-' + month;

      const inspectionSituationPerMonth = this.inspectionStatistics.situationByMonth.find((situation: any) =>
        situation.name === name);

      if (inspectionSituationPerMonth && inspectionSituationPerMonth.partitions) {
        const inDemonstration = inspectionSituationPerMonth.partitions.find((partition) =>
          partition?.name === EquipmentSituation.FOUND)?.count ?? 0;

        inDemoArray.push(inDemonstration);
      } else {
        inDemoArray.push(0);
      }
    });

    return inDemoArray;
  }

  private get inDemonstrationByMonth() {
    const inDemoArray: number[] = [];

    this.labels.numbers.forEach((date: any) => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString();
      const name = year + '-' + month;

      const inspectionSituationPerMonth = this.inspectionStatistics.situationByMonth.find((situation: any) =>
        situation.name === name);

      if (inspectionSituationPerMonth && inspectionSituationPerMonth.partitions) {
        const inDemonstration = inspectionSituationPerMonth.partitions.find((partition) =>
          partition?.name === EquipmentSituation.IN_DEMONSTRATION)?.count ?? 0;

        inDemoArray.push(inDemonstration);
      } else {
        inDemoArray.push(0);
      }
    });

    return inDemoArray;
  }

  private get inRentalByMonth() {
    const inRentalArray: number[] = [];
    this.labels.numbers.forEach((date: any) => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString();
      const name = year + '-' + month;

      const inspectionSituationPerMonth = this.inspectionStatistics.situationByMonth.find((situation: any) =>
        situation.name === name);

      if (inspectionSituationPerMonth && inspectionSituationPerMonth.partitions) {
        const inRental = inspectionSituationPerMonth.partitions.find((partition) =>
          partition?.name === EquipmentSituation.RENTAL)?.count ?? 0;

        inRentalArray.push(inRental);
      } else {
        inRentalArray.push(0);
      }
    });
    return inRentalArray;
  }

  private get soldByMonth() {
    const inSoldArray: number[] = [];
    this.labels.numbers.forEach((date: any) => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString();
      const name = year + '-' + month;

      const inspectionSituationPerMonth = this.inspectionStatistics.situationByMonth.find((situation: any) =>
        situation.name === name);

      if (inspectionSituationPerMonth && inspectionSituationPerMonth.partitions) {
        const inSold = inspectionSituationPerMonth.partitions.find((partition) =>
          partition?.name === EquipmentSituation.SOLD)?.count ?? 0;
        inSoldArray.push(inSold);
      } else {
        inSoldArray.push(0);
      }
    });
    return inSoldArray;
  }

  private get otherByMonth() {
    const inOtherArray: number[] = [];
    this.labels.numbers.forEach((date: any) => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString();
      const name = year + '-' + month;

      const inspectionSituationPerMonth = this.inspectionStatistics.situationByMonth.find((situation: any) =>
        situation.name === name);

      if (inspectionSituationPerMonth && inspectionSituationPerMonth.partitions) {
        const other = inspectionSituationPerMonth.partitions.filter((partition) => {
          if (!partition) {
            return false;
          }

          return [
            EquipmentSituation.FOUND as string, EquipmentSituation.SOLD as string,
            EquipmentSituation.RENTAL as string, EquipmentSituation.IN_DEMONSTRATION as string,
          ].indexOf(partition.name) < 0;
        }).reduce((total: number, partition) => {
          return total + (partition?.count ?? 0);
        }, 0);

        inOtherArray.push(other);
      } else {
        inOtherArray.push(0);
      }
    });
    return inOtherArray;
  }

  get chartDataEquipmentSituation() {
    if (this.inspectionStatistics && this.inDemonstrationByMonth && this.soldByMonth &&
      this.inRentalByMonth && this.otherByMonth) {
          return [{
            values: this.foundByMonth,
            chartType: 'bar',
            name: getEquipmentSituationTranslation(EquipmentSituation.FOUND),
          },
          {
            values: this.soldByMonth,
            chartType: 'bar',
            name: getEquipmentSituationTranslation(EquipmentSituation.SOLD),
          },
          {
            values: this.inDemonstrationByMonth,
            chartType: 'bar',
            name: getEquipmentSituationTranslation(EquipmentSituation.IN_DEMONSTRATION),
          },
          {
            values: this.inRentalByMonth,
            chartType: 'bar',
            name: getEquipmentSituationTranslation(EquipmentSituation.RENTAL),
          },
          {
            values: this.otherByMonth,
            chartType: 'bar',
            name: getEquipmentSituationTranslation(EquipmentSituation.OTHER_SITUATION),
          },
        ];
    }
  }
}

