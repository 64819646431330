





import { Component, Vue, Prop } from 'vue-property-decorator';
import NumberCard from '../../dealer/cards/NumberCard.vue';
import { DashboardStatistics_auditStatistics, AuditStatus } from '@/types/intrador';

@Component({
  components: {
    NumberCard,
  },
})
export default class PerformedAudits extends Vue {
  @Prop(Object) private auditStatistics!: DashboardStatistics_auditStatistics;
  @Prop(String) private currentDateId!: string;

  private get performedAudits() {
    const statusThisMonth = this.auditStatistics.byStatusByMonth.find((status) =>
      status?.name === this.currentDateId);

    if (statusThisMonth && statusThisMonth.partitions) {
      return statusThisMonth.partitions.find((partition) =>
        partition?.name === AuditStatus.CLOSED)?.count ?? 0;
    } else {
      return 0;
    }
  }
}
