
























import {Component, Mixins, Prop, Vue} from 'vue-property-decorator';
import ActionMixin from '@/layouts/back-office/elements/actions/ActionMixin';
import DownloadActionModal from '@/layouts/back-office/elements/actions/DownloadActionModal.vue';
import Toggle from '@/components/elements/button/Toggle.vue';
import InputSelect from '@/layouts/back-office/elements/input/InputSelect.vue';
import {InputSelectOptions} from '@/layouts/back-office/elements/input/InputSelectMixin';
import {longMonthTranslation} from '@/filters/vue/dateTime';

interface Month {
  year: number;
  month: number;
}

@Component({
  components: {InputSelect, Toggle, DownloadActionModal},
})
export default class ExportAuditsExcel extends Mixins(ActionMixin) {
  public title: string = this.$it('dashboard.action.export-audits.title', 'Export Audits') as string;
  public icon?: string = 'upload';
  public permission: string = 'inspections-export';

  @Prop({type: String, default: 'audits.xlsx'}) protected filename!: boolean;
  @Prop({type: Boolean, default: false}) protected selectDate!: boolean;

  protected month: Month | null = null;

  get months(): InputSelectOptions {
    const currentDate = new Date();

    const availableMonths: {[key: string]: Month; } = {};

    while (Object.keys(availableMonths).length < 12) {
      const name = longMonthTranslation(currentDate.getMonth() + 1) + ' ' + currentDate.getFullYear();

      availableMonths[name] = {year: currentDate.getFullYear(), month: currentDate.getMonth()};

      currentDate.setMonth(currentDate.getMonth() - 1);
    }

    return availableMonths;
  }

  get variables() {
    let data: any = {};

    if (this.selected.length > 0) {
      data.iids = this.selected.map((item: any) => item.id);
    }

    if (this.month !== null) {
      const start = new Date(this.month.year, this.month.month, 1, 0, 0, 0);
      const end = new Date(this.month.year, this.month.month + 1, 0, 23, 59, 59);

      data = {
        ...data,
        'date[start]': start,
        'date[end]': end,
      };
    }

    return data;
  }

  get canSave() {
    return !this.selectDate || this.month !== null;
  }

  protected onSelect() {
    const modal: DownloadActionModal = this.$refs.actionModal as DownloadActionModal;

    modal.open();
  }
}
