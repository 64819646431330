var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page page-dashboard"},[_c('apollo-query',{ref:"apolloQuery",attrs:{"query":require('@/graphql/DashboardStatistics.gql'),"variables":_vm.variables},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var loading = ref_result.loading;
var error = ref_result.error;
var data = ref_result.data;
var query = ref.query;
var isLoading = ref.isLoading;
return [(error)?[_c('div',{staticClass:"alert alert-danger"},[_c('strong',[_vm._v(_vm._s(_vm.$it('global.error', 'Something went wrong, please try again')))]),_c('p',[_vm._v(_vm._s(error))])])]:_vm._e(),_c('statistics',{attrs:{"is-loading":isLoading === 1,"data":data,"loading":loading,"title":_vm.$it('dashboard.dashboard.title', 'Management Dashboard')}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }