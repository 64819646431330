var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data)?_c('monthly-statistics',{attrs:{"audit-statistics":_vm.data.auditStatistics,"loading":_vm.isLoading,"title":_vm.title},scopedSlots:_vm._u([{key:"data-table-prepend",fn:function(ref){
var pagination = ref.pagination;
return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('to-plan-audits',{attrs:{"to-plan-audits-amount":_vm.data.auditStatistics.toPlan}})],1),_c('div',{staticClass:"col"},[_c('in-progress-audits',{attrs:{"audit-statistics":_vm.data.auditStatistics}})],1),_c('div',{staticClass:"col"},[_c('performed-audits',{attrs:{"audit-statistics":_vm.data.auditStatistics,"current-date-id":_vm.currentDateVariable}})],1),_c('div',{staticClass:"col"},[_c('audits-with-anomalies',{attrs:{"audit-statistics":_vm.data.auditStatistics,"current-date-id":_vm.currentDateVariable}})],1),_c('div',{staticClass:"col"},[_c('inspections',{attrs:{"audit-statistics":_vm.data.auditStatistics,"current-date-id":_vm.currentDateVariable}})],1)]),_c('div',{staticClass:"row"},[(_vm.data)?_c('div',{staticClass:"col"},[_c('monthly-audited-locations',{attrs:{"audit-statistics":_vm.data.auditStatistics,"total-locations":_vm.data.paginateBranches.total,"labels":_vm.labels,"loading":_vm.isLoading || _vm.loading}}),_c('monthly-rated-audits',{attrs:{"audit-statistics":_vm.data.auditStatistics,"labels":_vm.labels,"loading":_vm.isLoading || _vm.loading}}),(_vm.data.inspectionStatistics)?_c('monthly-inspections-situations',{attrs:{"inspection-statistics":_vm.data.inspectionStatistics,"labels":_vm.labels,"loading":_vm.isLoading || _vm.loading}}):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h5',{staticClass:"statistics-title"},[_vm._v(" "+_vm._s(_vm.$it('dashboard.monthly-statistics.data.title', 'Monthly Statistics'))+" ")])])])]}},{key:"month",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('div',{staticClass:"month"},[_vm._v(_vm._s(_vm.longMonthTranslation(value.getMonth() + 1))+" "+_vm._s(value.getFullYear()))])]}},{key:"number",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('div',{staticClass:"number"},[_vm._v(_vm._s(value))])]}}],null,false,2789645235)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }