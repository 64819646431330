





import {Component, Prop, Vue} from 'vue-property-decorator';
import NumberCard from '../../dealer/cards/NumberCard.vue';

@Component({
  components: {
    NumberCard,
  },
})
export default class ToPlanAudits extends Vue {
  @Prop(Number) private toPlanAuditsAmount!: number;
}
