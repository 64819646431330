var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.auditStatistics)?_c('data-table',{attrs:{"title":_vm.title,"recycle-scroller":false,"columns":_vm.columns,"filters":_vm.filters,"data":_vm.tableData,"loading":_vm.isLoading,"row-height":42,"buffer":600,"default-sort":"month"},scopedSlots:_vm._u([{key:"action-bar-buttons",fn:function(){return [_c('action-button',{attrs:{"title":_vm.$it('global.downloads', 'Downloads'),"direction":_vm.direction,"multiple":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var register = ref.register;
return [_c('export-audits-excel',{attrs:{"register":register,"selectDate":"","single":"","allowEmpty":""}}),_c('export-inspections-excel',{attrs:{"register":register,"selectDate":"","single":"","allowEmpty":""}})]}}],null,false,1143565314)})]},proxy:true},{key:"data-table-prepend",fn:function(ref){
var pagination = ref.pagination;
return [_vm._t("data-table-prepend")]}},{key:"month",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('div',{staticClass:"month"},[_vm._v(_vm._s(_vm.longMonthTranslation(value.getMonth() + 1))+" "+_vm._s(value.getFullYear()))])]}},{key:"number",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('div',{staticClass:"number"},[_vm._v(_vm._s(value))])]}}],null,true)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }