
































import AccountManagerFilterMixin from '@/filters/dataTable/AccountManagerFilterMixin';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import DataTable from '../../../../layouts/back-office/elements/datatable/DataTable.vue';
import ActionButton from '../../../../layouts/back-office/elements/actions/ActionButton.vue';
import ExportAuditsExcel from '../../actions/ExportAuditsExcel.vue';
import ExportInspectionsExcel from '../../actions/ExportInspectionsExcel.vue';
import { AuditStatus, DashboardStatistics_auditStatistics } from '@/types/intrador';
import { Filter } from '@/layouts/back-office/elements/filters/FilterMixin';
import { DataTableColumn } from '@/layouts/back-office/elements/datatable/DataTableMixin';
import { ActionMenuDirection } from '@/layouts/back-office/elements/ActionMenu.vue';
import { longMonthTranslation, shortMonthTranslation } from '@/filters/vue/dateTime';
import { ChartLabel } from '@/pages/Dashboard.vue';

interface TableData {
  id: string;
  month: Date;
  audits: number;
  dealer: number;
  inspection: number;
  location: number;
  closed: number;
  withAnomaliesByMonth: number;
}

@Component({
  components: {
    ActionButton,
    DataTable,
    ExportAuditsExcel,
    ExportInspectionsExcel,
  },
})
export default class MonthlyStatistics extends Mixins(AccountManagerFilterMixin) {
  @Prop(Object) private auditStatistics!: DashboardStatistics_auditStatistics;
  @Prop(Boolean) private isLoading!: boolean;
  @Prop(String) private title!: string;

  private direction: ActionMenuDirection = ActionMenuDirection.BottomRight;
  private longMonthTranslation = longMonthTranslation;

  private get tableData() {
    const selectedDate = new Date();
    const labels: ChartLabel = { names: [], numbers: [] };

    for (let i = 0; i < 12; i++) {
      labels.names.push(shortMonthTranslation(selectedDate.getMonth() + 1) + ' ' + selectedDate.getFullYear());
      labels.numbers.push(new Date(selectedDate));

      selectedDate.setMonth(selectedDate.getMonth() - 1);
    }

    const table: TableData[] = [];

    if (this.auditStatistics) {
      labels.numbers.forEach((date: Date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString();
        const name = year + '-' + month;

        const tableRow: TableData = {
          id: name,
          month: date,
          dealer: 0,
          location: 0,
          audits: 0,
          closed: 0,
          withAnomaliesByMonth: 0,
          inspection: 0,
        };

        if (this.auditStatistics?.dealersByMonth) {
          tableRow.dealer = this.auditStatistics?.dealersByMonth
          .find((dealer) => dealer?.name === name)
            ?.count ?? 0;
        }

        if (this.auditStatistics?.addressesByMonth) {
          tableRow.location = this.auditStatistics.addressesByMonth
          .find((location) => location?.name === name)
            ?.count ?? 0;
        }

        if (this.auditStatistics?.byStatusByMonth) {
          const statusPerMonth = this.auditStatistics.byStatusByMonth.find((status) => status?.name === name);
          if (statusPerMonth && statusPerMonth.partitions) {
            const totalAudits = statusPerMonth.partitions.map((partition) => partition?.count);
            tableRow.audits = totalAudits.reduce((a: number, b: number | undefined) => {
              if (b) {
                return a + b;
              }
              return a;
            }, 0) as number;

            tableRow.closed = statusPerMonth.partitions.find((partition) =>
              partition?.name === AuditStatus.CLOSED)?.count ?? 0;
          } else {
            tableRow.audits = 0;
            tableRow.closed = 0;
          }
        }

        if (this.auditStatistics?.withAnomaliesByMonth) {
          const withAnomaliesPerMonth = this.auditStatistics.withAnomaliesByMonth.find((withAnomaly) =>
            withAnomaly?.name === name);
          if (withAnomaliesPerMonth) {
            tableRow.withAnomaliesByMonth = withAnomaliesPerMonth.count;
          } else {
            tableRow.withAnomaliesByMonth = 0;
          }
        }

        if (this.auditStatistics?.inspectionsByMonth) {
          const inspectionsPerMonth = this.auditStatistics.inspectionsByMonth.find((inspection) =>
            inspection?.name === name);
          if (inspectionsPerMonth) {
            tableRow.inspection = inspectionsPerMonth.count;
          } else {
            tableRow.inspection = 0;
          }
        }

        table.push(tableRow);
      });

      return table;
    }

    return [];
  }

  get filters(): Filter[] {
    return [
      this.accountManagerFilter,
    ];
  }

  get columns(): DataTableColumn[] {
    return [
      {
        title: this.$it('dashboard.col.month.title', 'Month') as string,
        key: 'month',
        slot: 'month',
        sortable: true,
      },
      {
        title: this.$it('dashboard.col.dealers.title', 'Dealers') as string,
        key: 'dealer',
        slot: 'number',
        sortable: true,
      },
      {
        title: this.$it('dashboard.col.locations.title', 'Locations') as string,
        key: 'location',
        slot: 'number',
        sortable: true,
      },
      {
        title: this.$it('dashboard.col.total-audits.title', 'Audits') as string,
        key: 'audits',
        slot: 'number',
        sortable: true,
      },
      {
        title: this.$it('dashboard.col.closed-audits.title', 'Closed Audits') as string,
        key: 'closed',
        slot: 'number',
        sortable: true,
      },
      {
        title: this.$it('dashboard.col.audits-with-anomalies.title', 'Audits with Anomalies') as string,
        key: 'withAnomaliesByMonth',
        slot: 'number',
        sortable: true,
      },
      {
        title: this.$it('dashboard.col.inspections.title', 'Inspections') as string,
        key: 'inspection',
        slot: 'number',
        sortable: true,
      },
    ];
  }
}
