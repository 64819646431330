


















import { Component, Prop, Vue } from 'vue-property-decorator';
import { AuditRating, DashboardStatistics_auditStatistics } from '@/types/intrador';
import { VueFrappe } from 'vue2-frappe';
import { ChartLabel } from '@/pages/Dashboard.vue';
import Card from '@/layouts/back-office/elements/Card.vue';
import Spinner from '@/layouts/back-office/elements/Spinner.vue';

@Component({
  components: {
    Card,
    Spinner,
    VueFrappe,
  },
})

export default class MonthlyRatedAudits extends Vue {
  @Prop(Object) protected auditStatistics!: DashboardStatistics_auditStatistics;
  @Prop(Object) protected labels!: ChartLabel;
  @Prop(Boolean) protected loading!: boolean;

  private get contentByMonth() {
    const contentArray: number[] = [];
    this.labels.numbers.forEach((date: any) => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString();
      const name = year + '-' + month;

      const ratingPerMonth = this.auditStatistics.byRatingByMonth.find((rating: any) => rating.name === name);

      if (ratingPerMonth && ratingPerMonth.partitions) {
        const content = ratingPerMonth.partitions.find((partition) =>
          partition?.name === AuditRating.CONTENT)?.count ?? 0;

        contentArray.push(content);
      } else {
        contentArray.push(0);
      }
    });
    return contentArray;
  }

  private get halfContentByMonth() {
    const halfContentArray: number[] = [];
    this.labels.numbers.forEach((date: any) => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString();
      const name = year + '-' + month;

      const ratingPerMonth = this.auditStatistics.byRatingByMonth.find((rating: any) => rating.name === name);

      if (ratingPerMonth && ratingPerMonth.partitions) {
        const halfContent = ratingPerMonth.partitions.find((partition) =>
          partition?.name === AuditRating.HALF_CONTENT)?.count ?? 0;

        halfContentArray.push(halfContent);
      } else {
        halfContentArray.push(0);
      }
    });
    return halfContentArray;
  }

  private get notContentByMonth() {
    const notContentArray: number[] = [];
    this.labels.numbers.forEach((date: any) => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString();
      const name = year + '-' + month;

      const ratingPerMonth = this.auditStatistics.byRatingByMonth.find((rating: any) => rating.name === name);

      if (ratingPerMonth && ratingPerMonth.partitions) {
        const notContent = ratingPerMonth.partitions.find((partition) =>
          partition?.name === AuditRating.NOT_CONTENT)?.count ?? 0;

        notContentArray.push(notContent);
      } else {
        notContentArray.push(0);
      }
    });
    return notContentArray;
  }

  get chartDataRating() {
    if (this.auditStatistics && this.contentByMonth && this.halfContentByMonth && this.notContentByMonth) {
      const a = [
        {
          values: this.contentByMonth,
          chartType: 'bar',
          name: this.$it('audit.rating.content.title', 'Satisfactory') as string,
        },
        {
          values: this.halfContentByMonth,
          chartType: 'bar',
          name: this.$it('audit.rating.half-half-content.title', 'With reservations') as string,
        },
        {
          values: this.notContentByMonth,
          chartType: 'bar',
          name: this.$it('audit.rating.not-content.title', 'Unsatisfactory') as string,
        },
      ];

      // We do this because Frappe is not redrawing automatically
      // this.$nextTick(() => {
      //   this.$forceUpdate();
      // });

      return a;
    }
  }
}

