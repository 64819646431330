





import { Component, Vue, Prop } from 'vue-property-decorator';
import NumberCard from '../../dealer/cards/NumberCard.vue';
import { DashboardStatistics_auditStatistics, AuditStatus } from '@/types/intrador';

@Component({
  components: {
    NumberCard,
  },
})
export default class InProgressAudits extends Vue {
  @Prop(Object) private auditStatistics!: DashboardStatistics_auditStatistics;

  private get inProgressAudits() {
    return this.auditStatistics.byStatus.find((partition) =>
      partition?.name === AuditStatus.IN_PROGRESS)?.count ?? 0;
  }
}
