import { render, staticRenderFns } from "./MonthlyStatistics.vue?vue&type=template&id=19a7c5df&scoped=true&"
import script from "./MonthlyStatistics.vue?vue&type=script&lang=ts&"
export * from "./MonthlyStatistics.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19a7c5df",
  null
  
)

export default component.exports